<template>
  <div
    class="flex flex-wrap gap-x-2 text-2xs sm:text-sm 3xl:text-base justify-start items-center leading-normal"
  >
    <div class="font-secondary opacity-80 font-regular w-full xl:w-auto">
      <template v-for="(code, index) in tagCodes">
        <span
          v-if="
            getSpecificTag(code, tagsEnum) &&
            getSpecificTag(code, tagsEnum).category === TAG_TYPES.cinemaHall
          "
          :key="`hallTags-${index}`"
          class="inline-block mb-1"
        >
          {{ getSpecificTag(code, tagsEnum).title }}&nbsp;
        </span>
      </template>
    </div>
    <span
      v-if="item?.Accessibility"
      class="px-2.5 py-1 border border-white uppercase inline-flex items-center whitespace-nowrap mb-1"
    >
      <span
        :class="
          !Number.isNaN(Number(item?.Accessibility ?? 'NaN')) ? '-mr-0.5' : ''
        "
      >
        {{
          Number.isNaN(Number(item?.Accessibility ?? "NaN"))
            ? item?.Accessibility
            : `${item?.Accessibility}+`
        }}
      </span>
    </span>
    <template v-for="(code, index) in tagCodes">
      <span
        v-if="
          getSpecificTag(code, tagsEnum) &&
          getSpecificTag(code, tagsEnum).category === TAG_TYPES.subMovie
        "
        :key="`smallTags-${index}`"
        class="inline-block mb-1"
      >
        {{ getSpecificTag(code, tagsEnum).title }}
      </span>
    </template>
    <span
      v-if="!Properties?.['29'] || !Properties?.['42']"
      class="inline-block mb-1"
    >
      {{ item?.Movie3D ? "3D" : "2D" }}
    </span>
  </div>
</template>
<script lang="ts" setup>
import { computed, getSpecificTag, TAG_TYPES } from "#imports";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  tagsEnum: {
    type: Array,
    default: () => [],
  },
});

const Properties = computed(() =>
  Array.isArray(props?.item?.Properties)
    ? props?.item?.Properties?.reduce?.(
        (acc, prop) => ({ ...acc, [`${prop.Code}`]: prop.Name }),
        {},
      )
    : props?.item?.Properties,
);

const tagCodes = computed(() => {
  const codes = new Set();
  if (Properties.value) {
    Object.keys(Properties.value).forEach((property) => {
      codes.add(Number(property));
    });
  }
  if (props.item.categoryTags) {
    props.item.categoryTags.forEach((category) => {
      codes.add(Number(category.tagCode));
    });
  }
  // Preserve Sorting and prioritizing
  if (codes.has(29)) {
    codes.delete(42);
    codes.delete(3);
  }
  return codes;
});
</script>
