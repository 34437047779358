<template>
  <component
    :is="getElementType"
    v-bind="elementPropertiesHandler"
    class="relative flex-wrap pl-6 gap-2 2xs:gap-3 md:gap-2 xl:gap-5 2xl:gap-10 3xl:gap-16 xl:px-12 3xl:px-16"
    :class="[getWrapperStyle, hideOnMobile ? 'hidden sm:flex' : 'flex']"
  >
    <div class="w-1/2 relative z-10 py-6 xl:py-12 3xl:py-16">
      <div class="w-full">
        <p
          v-if="item?.title"
          class="w-full container-xl-headline mb-4 md:mb-3 lg:mb-4"
        >
          {{ item.title }}
        </p>
        <p v-if="item?.description" class="w-full container-xl-text">
          {{ item.description }}
        </p>
        <div class="mt-4 xl:mt-10 2xl:flex 2xl:flex-wrap 2xl:gap-x-4">
          <template v-if="item?.buttonCustom">
            <AppButton
              v-if="item?.buttonCustom !== 'none'"
              element="a"
              :href="
                item.buttonCustomLink
                  ? item.buttonCustomLink
                  : getCampaignDetailLink()
              "
              :text="item.buttonCustomText"
              :variant="item.buttonCustom"
              size="small"
            />
          </template>
          <template v-else>
            <AppButton
              v-if="item && item.buttonDetail !== 'none'"
              v-bind="elementPropertiesHandler"
              :text="$getTranslate('button_title_buy_tickets')"
              :variant="item.buttonDetail"
              size="small"
              :class="{
                'mb-3 xl:mb-4 2xl:mb-0': item.buttonTrailer !== 'none',
              }"
            />
            <div
              class="w-full pointer-events-none 2xl:hidden"
              aria-hidden="true"
            />
            <AppButton
              v-if="
                item &&
                item.buttonTrailer !== 'none' &&
                getTrailer(item.movie)?.Url
              "
              :text="$getTranslate('button_title_play_trailer')"
              :variant="item.buttonTrailer"
              size="small"
              @click.prevent="showModal = true"
            >
              <template #iconLeft>
                <component :is="iconPlay" />
              </template>
            </AppButton>
            <TrailerModal
              v-if="showModal"
              :movie="item.movie?.[0] ?? item.movie"
              @close="showModal = false"
            />
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="image?.length > 0"
      class="flex-1 relative transform-3d-left my-6 lg:my-0"
    >
      <img
        :src="image[0]?.url ?? image[0]"
        :alt="image[0].title ?? ''"
        loading="lazy"
        class="absolute top-1/2 right-0 bottom-0 -translate-y-1/2 2xl:max-h-[110%] <lg:translate-x-2 <xl:max-h-full"
      />
    </div>
  </component>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from "#imports";

const VARIANTS = {
  COLOR: {
    primary: "bg-gradient-brand-base",
    secondary: "bg-gradient-brand-magenta",
    blue: "bg-gradient-brand-blue",
    csfd: "bg-gradient-csfd",
    gold: "bg-gradient-gold",
    deluxe: "bg-gradient-deluxe",
    club: "bg-gradient-cinestar-club",
  },
};

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    item: {
      type: Object,
      required: true,
    },
    uri: {
      type: String,
      default: null,
    },
    hideOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const iconPlay = computed(() =>
      defineAsyncComponent(() => import("~/assets/svg/play.svg")),
    );

    const route = useRoute();

    const showModal = ref(false);

    const getWrapperStyle = computed(
      () => VARIANTS?.COLOR?.[props.variant] ?? "",
    );

    const getTrailer = (movie) => movie?.[0]?.CSFD?.[0]?.Videos?.[0] ?? null;

    const getCampaignDetailLink = () =>
      `/${route.params?.lang}/${route.params?.cinemaslug}/${props.uri}`;

    const getElementType = computed(() => {
      if (props.uri) return "a";
      return "div";
    });

    const elementPropertiesHandler = computed(() => {
      if (props.uri) {
        return {
          href: getCampaignDetailLink(),
        };
      }
      return {};
    });

    return {
      getElementType,
      elementPropertiesHandler,
      iconPlay,
      showModal,
      getWrapperStyle,
      getTrailer,
      getCampaignDetailLink,
      image: computed(() =>
        [
          ...(props.item?.image ?? []),
          ...(props.item?.movie?.moviePoster ?? []),
          ...(props.item?.movie?.[0]?.moviePoster ?? []),
          ...(props.item?.movieGroup?.[0]?.moviePoster ?? []),
          props.item?.movie?.[0]?.PictureUrl ?? null,
          props.item?.movie?.[0]?.movie?.[0]?.PictureUrl ?? null,
          props.item?.movie?.[0]?.movieGroup?.[0]?.PictureUrl ?? null,
        ].filter((x) => x && (x?.includes?.("//") || x?.url?.includes?.("//"))),
      ),
    };
  },
});
</script>
