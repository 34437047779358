<script setup lang="ts">
import { computed, getMovieVersionTags } from "#imports";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  tagsEnum: {
    type: Array,
    default: () => [],
  },
});

const Properties = computed(() =>
  Array.isArray(props?.item?.Properties)
    ? props?.item?.Properties?.reduce?.(
        (acc, prop) => ({ ...acc, [`${prop.Code}`]: prop.Name }),
        {},
      )
    : props?.item?.Properties,
);

const tagCodes = computed(() => {
  const codes = new Set();
  if (Properties.value) {
    Object.keys(Properties.value).forEach((property) => {
      codes.add(Number(property));
    });
  }
  if (props.item.categoryTags) {
    props.item.categoryTags.forEach((category) => {
      codes.add(Number(category.tagCode));
    });
  }
  // Preserve Sorting and prioritizing
  if (codes.has(29)) {
    codes.delete(42);
    codes.delete(3);
  }
  return codes;
});

const movieVersionType = computed(() => {
  const MOVIE_VERSION_TAGS = getMovieVersionTags(props.tagsEnum);

  const foundTag = MOVIE_VERSION_TAGS.find((tag) => {
    return tagCodes.value.has(Number(tag.code));
  });

  if (foundTag) {
    return foundTag;
  }

  if (props.item.MovieSubtitles) {
    return MOVIE_VERSION_TAGS.find(
      (tag) => tag.versionType === MOVIE_VERSIONS.subtitles,
    );
  }

  if (props.item.MovieDubbing) {
    return MOVIE_VERSION_TAGS.find(
      (tag) => tag.versionType === MOVIE_VERSIONS.dubbing,
    );
  }

  return MOVIE_VERSION_TAGS.find(
    (tag) => tag.versionType === MOVIE_VERSIONS.original,
  );
});

const formatDateTime = (isoString) => {
  return new Date(isoString).toLocaleTimeString?.("cs-CZ", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
</script>

<template>
  <div class="flex flex-wrap w-full">
    <p v-if="item?.Start" class="mb-2 3xl:mb-4 opacity-80">
      {{ formatDateTime(item.Start) }}
    </p>
    <div v-if="movieVersionType" class="flex items-center mb-2 3xl:mb-4">
      <span class="block mx-2 opacity-80">|</span>
      <img
        v-if="movieVersionType.icon?.length > 0"
        :src="movieVersionType.icon[0].url"
        :alt="movieVersionType.icon[0].alt"
        class="w-4 h-4 inline-flex flex-shrink-0 mr-1"
      />
      <div class="font-secondary opacity-80">
        {{ movieVersionType.title }}
      </div>
    </div>
  </div>
</template>
